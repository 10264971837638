export function updateUrl(query, store, router) {
  const filteredQuery = Object.keys(query).reduce((acc, key) => {
    if (query[key] !== null && query[key] !== undefined && query[key] !== "") {
      acc[key] = query[key];
    }
    return acc;
  }, {});
  store.commit('route/SET_QUERY_PARAMS', filteredQuery);
  router.push({ path: '', query: filteredQuery }).catch(err => {
    if (err.name !== 'NavigationDuplicated') {
      throw err;
    }
  });
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row page-title-header"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"page-header"},[_c('b-breadcrumb',{staticClass:"m-0"},[_c('b-breadcrumb-item',{attrs:{"to":{
              name: 'DashboardHome',
            }}},[_c('i',{staticClass:"fa fa-home"})]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v(_vm._s(_vm.title))])],1)],1)])]),_c('b-card',[_c('div',{staticClass:"row d-flex mb-4 mb-xl-2 justify-content-between"},[_c('h4',{staticClass:"col-12 col-xl-4 mb-2 mb-xl-0 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"\n          col-12 col-xl-8\n          d-flex\n          flex-column flex-xl-row\n          align-items-start align-items-xl-center\n          mt-2 mt-xl-0\n        "},[_c('div',{staticClass:"flex-shrink-0"},[(_vm.checkPermission([_vm.consts.REDEEM_CODE_USED_LIST_VIEW]))?_c('b-button',{staticClass:"mb-2 mb-xl-0 mr-2 ml-n3 ml-xl-0",attrs:{"variant":"link","to":{
              name: 'BelongedRedeemCodeList',
            }}},[_c('i',{staticClass:"fa fa-folder-open"}),_vm._v("優惠券使用紀錄 ")]):_vm._e(),(
              _vm.checkPermission([_vm.consts.REDEEM_CODE_LIST_CREATE]) ||
              _vm.fromMyConsole
            )?_c('b-button',{staticClass:"mb-2 mb-xl-0 mr-2",attrs:{"variant":"primary"},on:{"click":_vm.handleCreate}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v("新增優惠券 ")]):_vm._e()],1),_c('b-form-select',{staticClass:"mr-2",attrs:{"options":_vm.createTypes},model:{value:(_vm.query.create_type),callback:function ($$v) {_vm.$set(_vm.query, "create_type", $$v)},expression:"query.create_type"}}),_c('b-form-select',{attrs:{"options":_vm.merchants},model:{value:(_vm.query.merchant_id),callback:function ($$v) {_vm.$set(_vm.query, "merchant_id", $$v)},expression:"query.merchant_id"}})],1)]),_c('b-table',{attrs:{"striped":"","hover":"","responsive":"","items":_vm.redeemCodeBundles,"fields":_vm.fields,"busy":_vm.isLoading},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]},proxy:true},{key:"cell(actions)",fn:function(data){return [_c('b-button',{staticClass:"ml-2",attrs:{"variant":"inverse-primary"},on:{"click":function($event){return _vm.showAssignUrlModal(data.item)}}},[_c('i',{staticClass:"fa fa-link ml-2"})]),(
            _vm.checkPermission([_vm.consts.REDEEM_CODE_LIST_MODIFY]) || _vm.fromMyConsole
          )?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"inverse-info","to":{
            name: _vm.fromMyConsole
              ? 'MyConsoleRedeemCodeBundleRedeemCodes'
              : 'RedeemCodeBundleRedeemCodes',
            params: {
              redeemCodeBundleId: data.item.id,
            },
          }}},[_vm._v("序號管理 ")]):_vm._e(),_c('b-button',{staticClass:"ml-2",attrs:{"variant":"inverse-secondary","to":{
            name: _vm.fromMyConsole
              ? 'MyConsoleRedeemCodeBundleDetail'
              : 'RedeemCodeBundleDetail',
            params: {
              redeemCodeBundleId: data.item.id,
            },
          }}},[_vm._v("檢視")]),(
            _vm.checkPermission([_vm.consts.REDEEM_CODE_LIST_MODIFY]) || _vm.fromMyConsole
          )?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"inverse-warning","to":{
            name: _vm.fromMyConsole
              ? 'MyConsoleRedeemCodeBundleEdit'
              : 'RedeemCodeBundleEdit',
            params: {
              redeemCodeBundleId: data.item.id,
            },
          }}},[_vm._v("編輯")]):_vm._e(),(
            _vm.checkPermission([_vm.consts.REDEEM_CODE_LIST_DELETE]) || _vm.fromMyConsole
          )?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"inverse-danger"},on:{"click":function($event){return _vm.handleDelete(data.item)}}},[_vm._v("刪除")]):_vm._e()]}}])}),_c('CustomPagination',{attrs:{"currentPage":_vm.query.page,"totalRows":_vm.total,"perPage":_vm.query.per_page,"perPageOptions":_vm.perPageOption},on:{"page-change":_vm.handlePageChange,"per-page-change":_vm.handlePerPageChange}})],1),_c('b-modal',{attrs:{"id":"assign-url-modal","footer-class":"d-flex justify-content-center","hide-header":""},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"size":"lg","variant":"danger"},on:{"click":function($event){return _vm.$bvModal.hide('assign-url-modal')}}},[_vm._v("關閉")])]},proxy:true}])},[_c('b-textarea',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.click",value:('已複製'),expression:"'已複製'",modifiers:{"click":true}}],staticClass:"mt-2",attrs:{"id":"assign-url"},on:{"click":_vm.copyAssignUrl},model:{value:(_vm.assignUrl),callback:function ($$v) {_vm.assignUrl=$$v},expression:"assignUrl"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }